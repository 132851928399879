.home {
    position: relative;
    top: 60px;

}



.home-images-header {
    width: 100%;
    height: 320px;
    display: flex;
    justify-content: center;
    position: relative;
    background: linear-gradient(53deg, rgba(196, 69, 74, 1) 35%, rgba(111, 150, 184, 1) 65%);
    z-index: 2;
}


.home-image {
    object-fit: scale-down;
    width: 400px;
    height: 400px;
    position: relative;
    z-index: 2;

}

.home-image-container::after {
    content: "";
    position: absolute;
    background-color: white;
    height: 50px;
    width: 100%;
    bottom: -5px;
    left: 0;
    filter: blur(4px);
    z-index: 0;
}

.home-products-container {
    padding-left: 20px;
    padding-right: 20px;
}


.home-sneakers-title-container {
    margin-left: 60px;
}

.home-sneakers-heading {
    font-size: 34px;
    margin-bottom: 10px;
}

.home-sneakers-count {
    color: rgb(100, 100, 100);
    font-size: 22px;
    margin-top: 0;
    font-weight: 500;
}

.home-sort-filter-container {
    display: flex;
    align-items: end;
    width: 380px;
    justify-content: space-between;
    margin-left: 60px;

}

.btn-filter {
    border: none;
    border-bottom: 1px solid rgb(50, 50, 50);
    background-color: transparent;
    width: 150px;
    text-align: start;
    letter-spacing: 1px;
}

.sort-container {
    width: 200px;
}

.form-select {
    border: none;
    border-bottom: 1px solid rgb(50, 50, 50);
    padding-left: 0;
    border-radius: 0
}

.home-products-grid-container {
    margin-top: 40px;
    display: grid;
    justify-content: center;
    grid-template-columns: 300px 300px 300px 300px;
    column-gap: 20px;
    row-gap: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 60px;
}

.home-products-grid-container-mobile{
    display: none;
    margin-top: 40px;
    justify-content: center;
    grid-template-columns: 300px 300px 300px 300px;
    column-gap: 20px;
    row-gap: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 60px;
}

.card {
    width: 300px;
    border: 2px solid rgb(60, 60, 60);
    border-radius: 10px;
    cursor: pointer;
    position: relative;
}

.card:hover {
    transform: scale(1.05);
    transition: transform 0.1s ease-out;
    background-color: rgb(230, 230, 230);
}






.heart-icon {
    margin-top: 16px;
    margin-left: 254px;
}

.heart-icon:hover {
    fill: red;
}



.card-img-top {
    width: 300px;
    height: 200px;
    object-fit: scale-down;
}

.card-body {
    position: relative;
    height: 200px;
}

.card-body::after {
    content: '';
    width: 260px;
    height: 1px;
    background-color: rgb(50, 50, 50);
    position: absolute;
    top: 0;
    left: 20px;
}



.card-title {
    font-size: 16px;
    color: rgb(100, 100, 100);
    font-weight: 500;
}

.card-price {
    font-size: 16px;
    color: rgb(50, 50, 50);
    font-weight: 600;

}

#old-price {
    text-decoration: line-through;
}



.add-btn {
    text-decoration: none;
    color: rgb(50, 50, 50);
    font-weight: 600;
    border: 1px solid black;
    padding: 5px 10px 5px 10px;
    border-radius: 8px;
    cursor: pointer;
    position: absolute;
    bottom: 16px;
    left: 16px;
}

.add-btn:hover,
.filters-btn:hover,
.load-more-btn:hover {
    color: white;
    background-color: rgb(50, 50, 50);
}

.filters-btn {
    color: rgb(50, 50, 50);
    font-weight: 600;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 16px;
    padding: 2px 6px 2px 6px;
}



#no-more-products-span,
#no-more-products-span-mobile {
    color: rgb(50, 50, 50);
    font-weight: 600;
    font-size: 26px;
    display: block;
    text-align: center;
    margin-bottom: 20px;
}

#no-more-products-span-mobile{
    display: none;
}

.load-more-btn-container,
.load-more-btn-container-mobile{
    width: 110px;
    margin-left: auto;
    margin-right: auto;
}

.load-more-btn-container-mobile{
    display: none;
}

.load-more-btn {
    color: rgb(50, 50, 50);
    font-weight: 600;
    border: 1px solid black;
    padding: 5px 10px 5px 10px;
    border-radius: 8px;
    cursor: pointer;

}

.load-more-btn:disabled{
    background-color: rgb(200, 200, 200);
    color: white;

}

.load-more-btn:disabled:hover{
    color: white;

}

@media screen and (max-width:1350px) {


    .home-products-grid-container {
        grid-template-columns: 260px 260px 260px 260px;
    }

    .card {
        width: 260px;

    }

    .heart-icon {
        margin-left: 214px;
    }

    .card-img-top {
        width: 260px;
        height: 180px;
    }

    .card-body {
        height: 180px;
    }

    .card-body::after {
        width: 220px;
    }

    .card-brand {
        font-size: 20px;
    }

    .card-title,
    .card-price {
        font-size: 14px;
    }

    .add-btn {
        padding: 3px 8px 3px 8px;
        font-size: 14px;
    }

}


@media screen and (max-width:1150px) {


    .home-products-grid-container {
        grid-template-columns: 230px 230px 230px 230px;
    }

    .card {
        width: 230px;

    }

    .heart-icon {
        margin-left: 184px;
        width: 26px;
        height: 26px;
    }

    .card-img-top {
        width: 230px;
        height: 180px;
    }

    .card-body {
        height: 180px;
    }

    .card-body::after {
        width: 190px;
    }

    .card-brand {
        font-size: 18px;
    }

    .card-title,
    .card-price {
        font-size: 12px;
    }

    .add-btn {
        padding: 2px 7px 2px 7px;
        font-size: 12px;
    }


}


@media screen and (max-width:1040px) {


    .home-products-grid-container {
        grid-template-columns: 200px 200px 200px 200px;
    }

    .home-sneakers-title-container,
    .home-sort-filter-container {
        margin-left: 30px;
    }

    .card {
        width: 200px;

    }

    .heart-icon {
        margin-left: 154px;
        width: 24px;
        height: 24px;
    }

    .card-img-top {
        width: 200px;
        height: 150px;
    }

    .card-body {
        height: 150px;
    }

    .card-body::after {
        width: 160px;
    }




}


@media screen and (max-width:920px) {


    .home-products-grid-container {
        grid-template-columns: 180px 180px 180px 180px;
    }

    .card {
        width: 180px;

    }

    .heart-icon {
        margin-left: 144px;
        width: 20px;
        height: 20px;
    }

    .card-img-top {
        width: 180px;
        height: 130px;
    }

    .card-body {
        height: 130px;
    }

    .card-body::after {
        width: 140px;
    }

    .card-brand {
        font-size: 16px;
    }

    .card-title,
    .card-price {
        font-size: 10px;
    }

    .add-btn {
        padding: 2px 7px 2px 7px;
        font-size: 10px;
        bottom: 8px;
    }


}



@media screen and (max-width:820px) {


    .home-products-grid-container {
        grid-template-columns: 160px 160px 160px 160px;
    }

    .card {
        width: 160px;

    }

    .heart-icon {
        margin-left: 124px;
        width: 20px;
        height: 20px;
    }

    .card-img-top {
        width: 160px;
        height: 130px;
    }

    .card-body {
        height: 130px;
    }

    .card-body::after {
        width: 120px;
    }

    .card-brand {
        font-size: 16px;
        margin-bottom: 4px;

    }

    .card-title,
    .card-price {
        margin-bottom: 4px;
    }

    .add-btn {
        padding: 2px 7px 2px 7px;
        font-size: 10px;
        bottom: 8px;
    }


}


@media screen and (max-width:750px) {


    .home-products-grid-container {
        grid-template-columns: 140px 140px 140px 140px;
    }

    .home-sneakers-title-container,
    .home-sort-filter-container {
        margin-left: 10px;
    }

    .card {
        width: 140px;

    }

    .heart-icon {
        margin-left: 104px;
        width: 18px;
        height: 18px;
    }

    .card-img-top {
        width: 140px;
        height: 130px;
    }

    .card-body {
        height: 130px;
    }

    .card-body::after {
        width: 100px;
    }

    .home-sneakers-heading {
        font-size: 26px;
    }

    .home-sneakers-count {
        font-size: 20px;
    }


}



@media screen and (max-width:680px) {

    .home-products-grid-container {
        display: none;
    }

    .home-products-grid-container-mobile {
        display: grid;
        grid-template-columns: 300px;
    }

    .home-sneakers-title-container,
    .home-sort-filter-container {
        margin-left: 40px;
    }

    .card {
        width: 300px;

    }

    .heart-icon {
        margin-left: 254px;
        width: 28px;
        height: 28px;
    }

    .card-img-top {
        width: 300px;
        height: 200px;
    }

    .card-body {
        height: 200px;
    }

    .card-body::after {
        width: 260px;
    }

    .card-brand {
        font-size: 20px;
    }

    .card-title,
    .card-price {
        font-size: 16px;
        margin-bottom: 16px;
    }

    .add-btn {
        bottom: 16px;
        padding: 5px 10px 5px 10px;
        font-size: 14px;
    }

    #no-more-products-span-mobile,
    .load-more-btn-container-mobile{
        display: block;
    }

    #no-more-products-span,
    .load-more-btn-container{
        display: none;
    }
}


@media screen and (max-width:560px) {

    .home-sneakers-title-container,
    .home-sort-filter-container {
        margin-left: 20px;
    }

    .home-sort-filter-container {
        width: 300px;
    }

    .sort-container,
    .filter-container,
    .btn-filter {
        width: 130px;
    }


}

@media screen and (max-width:450px) {


    .home-products-grid-container-mobile {
        grid-template-columns: 260px;
    }

    .home-sneakers-title-container,
    .home-sort-filter-container {
        margin-left: 20px;
    }

    .card {
        width: 260px;

    }

    .heart-icon {
        margin-left: 214px;
        width: 24px;
        height: 24px;
    }

    .card-img-top {
        width: 260px;
        height: 160px;
    }

    .card-body {
        height: 160px;
    }

    .card-body::after {
        width: 220px;
    }

    .card-brand {
        font-size: 18px;
    }

    .card-title,
    .card-price {
        font-size: 14px;
        margin-bottom: 8px;
    }

    .add-btn {
        bottom: 16px;
        padding: 3px 7px 3px 7px;
        font-size: 12px;
    }
}


@media screen and (min-width:320px) and (max-width:360px) {

    .home-sneakers-title-container,
    .home-sort-filter-container {
        margin-left: 0;
    }

    .home-sort-filter-container {
        width: 260px;
    }

    .sort-container,
    .filter-container,
    .btn-filter {
        width: 120px;
    }


}


@media screen and (max-width:960px) {
    .home-image {
        width: 300px;
        height: 300px;
    }



    .home-images-header {
        height: 250px;
    }


}


@media screen and (max-width:600px) {
    .home-image {
        width: 250px;
        height: 250px;
    }

    .home-images-header {
        height: 220px;
    }

}







@media screen and (max-width:520px) {
    .home-image {
        width: 225px;
        height: 225px;
    }

    .home-images-header {
        height: 200px;
    }


}


@media screen and (max-width:450px) {
    .home-image {
        width: 200px;
        height: 200px;
    }

    .home-images-header {
        height: 185px;
    }

}



@media screen and (max-width:400px) {

    .home-image {
        width: 180px;
        height: 180px;
    }

    .home-images-header {
        height: 170px;
    }

}


@media screen and (min-width:320px) and (max-width:375px) {

    .home-image {
        width: 160px;
        height: 160px;
    }

    .home-images-header {
        height: 160px;
    }

}