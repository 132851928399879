.cart {
    position: relative;
    top: 100px;

}

.cart-container {
    display: flex;
    justify-content: center;
}

.cart-form-container {
    width: 1400px;
    height: 500px;
    border-radius: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    background-color: rgb(240, 240, 240);
}

.cart-form {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 20px;
}

.cart-product-container {
    width: 700px;
    position: relative;
    display: flex;
    align-items: start;
}

.cart-product {
    margin-bottom: 10px;
    width: 500px;
    word-break: normal;
}

.cart-product-price {
    position: absolute;
    left: 500px;
}

.remove-product-btn {
    position: absolute;
    left: 600px;
    cursor: pointer;
    font-weight: 600;
}

.remove-product-btn:hover {
    color: red;
}

.cart-go-back-link {
    text-decoration: none;
    color: rgb(50, 50, 50);
    font-weight: 600;
    border: 1px solid black;
    padding: 5px 10px 5px 10px;
    border-radius: 8px;
    cursor: pointer;
    position: absolute;
    bottom: 16px;
    left: 16px;
}

.cart-go-back-link:hover {
    color: white;
    background-color: rgb(50, 50, 50);

}

.cart-back-arrow-hovered {
    fill: white;
}

.checkout-btn {
    position: absolute;
    bottom: 16px;
    left: 256px;
    font-weight: 600;
    border: 1px solid black;
    padding: 5px 10px 5px 10px;
    border-radius: 8px;
    cursor: pointer;
    background-color: rgb(50, 50, 50);
    color: white;
    width: 200px;
    height: 40px;
}

.checkout-btn:hover {
    color: rgb(50, 50, 50);
    background-color: transparent;
}

.cart-products-heading {
    margin-bottom: 20px;
}



.total-price {
    margin-top: 20px;
    font-weight: 600;
    text-align: end;
    width: 610px;
}

@media screen and (min-width:1600px) {
    .cart-form-container {
        height: 700px;
    }
}

@media screen and (max-width:1500px) {
    .cart-form-container {
        height: 500px;
        width: 1300px;
    }
}

@media screen and (max-width:1400px) {
    .cart-form-container {
        width: 1200px;
    }
}

@media screen and (max-width:1300px) {
    .cart-form-container {
        width: 1100px;
    }
}

@media screen and (max-width:1200px) {
    .cart-form-container {
        width: 1000px;
    }
}

@media screen and (max-width:1100px) {
    .cart-form-container {
        width: 900px;
    }
}

@media screen and (max-width:1000px) {
    .cart-form-container {
        height: 400px;
        width: 800px;
    }
}

@media screen and (max-width:999px) {
    .cart-form-container{
        height: 500px;
    }
    
}

@media screen and (max-width:900px) {
    .cart-form-container {
        width: 700px;
    }
}

@media screen and (max-width:800px) {
    .cart-form-container {
        width: 600px;
    }

    .cart-product-container {
        width: 600px;
    }

    .cart-product {
        width: 400px;
    }

    .cart-product-price {
        left: 400px;
    }

    .remove-product-btn {
        left: 500px;
    }

    .total-price {
        width: 510px;
    }
}



@media screen and (max-width:680px) {
    .cart-form-container {
        width: 500px;
    }

    .cart-product-container {
        width: 500px;
    }

    .cart-product {
        width: 300px;
    }

    .cart-product-price {
        left: 300px;
    }

    .remove-product-btn {
        left: 400px;
    }

    .total-price {
        width: 410px;
    }
}

@media screen and (max-width:560px) {
    .cart-form-container {
        width: 450px;
    }

    .cart-product-container {
        width: 450px;
    }

    .cart-product {
        width: 250px;
    }

    .cart-product-price {
        left: 250px;
    }

    .remove-product-btn {
        left: 350px;
    }

    .total-price {
        width: 360px;
    }

    .cart-go-back-link {
        font-size: 14px;
        padding: 2px 7px 2px 7px;
    }

    .checkout-btn {
        font-size: 14px;
        padding: 2px 7px 2px 7px;
        height: 32px;
        width: 150px;
        left: 226px;
    }

    .cart-back-arrow-hovered,
    .cart-back-arrow {
        width: 24px;
        height: 24px;
    }
}


@media screen and (max-width:500px) {
    .cart-form-container {
        width: 400px;
    }

    .cart-product-container {
        width: 400px;
    }

    .cart-product {
        width: 200px;
    }

    .cart-product-price {
        left: 220px;
    }

    .remove-product-btn {
        left: 320px;
    }

    .total-price {
        width: 330px;
    }



}


@media screen and (max-width:450px) {
    .cart-form-container {
        width: 350px;
        font-size: 14px;
    }

    .cart-product-container {
        width: 350px;
    }

    .cart-product {
        width: 180px;
    }

    .cart-product-price {
        left: 200px;
    }

    .remove-product-btn {
        left: 280px;
    }

    .total-price {
        width: 300px;
    }

    .cart-go-back-link {
        font-size: 12px;
        padding: 2px 7px 2px 7px;
    }

    .checkout-btn {
        font-size: 14px;
        padding: 2px 7px 2px 7px;
        height: 32px;
        width: 120px;
        left: 206px;
    }

    .cart-back-arrow-hovered,
    .cart-back-arrow {
        width: 24px;
        height: 24px;
    }

}


@media screen and (max-width:400px) {
    .cart-form-container {
        width: 280px;
    }

    .cart-product-container {
        width: 280px;
    }

    .cart-product {
        width: 130px;
    }

    .cart-product-price {
        left: 140px;
    }

    .remove-product-btn {
        left: 220px;
    }

    .total-price {
        width: 240px;
    }

    .cart-go-back-link {
        font-size: 12px;
        padding: 2px 6px 2px 6px;
    }

    .checkout-btn {
        font-size: 14px;
        padding: 2px 7px 2px 7px;
        height: 26px;
        width: 90px;
        left: 186px;
    }

    .cart-back-arrow-hovered,
    .cart-back-arrow {
        width: 20px;
        height: 20px;
    }

}